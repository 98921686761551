import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ianeCard.css";
import defaultMosqueImage from "../../assets/images/mosque.png";
import img1 from "../../assets/images/mescid.png";
import image2 from "../../assets/images/address.png";
import image3 from "../../assets/images/repair.png";
import image4 from "../../assets/images/cost.png";

const MosquePayment = ({ findMescid }) => {
  const [mosqueImage, setMosqueImage] = useState(defaultMosqueImage);
  const location = useLocation();
  const navigate = useNavigate();

  const amount = location.state?.amount ? location.state.amount : "20";
  const mosqueId = location.state?.mosqueId || "default";

  useEffect(() => {
    const fetchMosqueImage = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const imageUrl = `https://example.com/mosque-image-${mosqueId}.jpg`;
        const img = new Image();
        img.onload = () => setMosqueImage(imageUrl);
        img.onerror = () => console.error("Error loading mosque image");
        img.src = imageUrl;
      } catch (error) {
        console.error("Error fetching mosque image:", error);
      }
    };

    fetchMosqueImage();
  }, [mosqueId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Payment submitted:", { amount, id:`${ findMescid.id }` });
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="mosque-payment-container">
      <div className="mosque-selecter-container-forX">
        <div className="mosque-payment">
          <button className="close-button" onClick={handleClose}>
            X
          </button>
          <div className="image-container">
            <img src={mosqueImage} alt="Mosque" />
          </div>
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div>
                <p className="text-[#000000]  text-[18px] font-bold mb-[34px] border-b">
                  İanə xülasəsi
                </p>
                <div style={styles.imageContainer}>
                  <img src={img1} alt="location" style={styles.image} />
                  <span style={styles.imageText}>{findMescid.name}</span>
                </div>
                <div style={styles.imageContainer}>
                  <img src={image2} alt="address" style={styles.image} />
                  <span style={styles.imageText}>{findMescid.address}</span>
                </div>
                <div style={styles.imageContainer}>
                  <img src={image3} alt="repair" style={styles.image} />
                  <span style={styles.imageText}>{findMescid.repairType}</span>
                </div>
                <div style={styles.imageContainer}>
                  <img src={image4} alt="cost" style={styles.image} />
                  <span style={styles.imageText}>{findMescid.cost} manat</span>
                </div>
              </div>
              <div
                className="amount-display border-t"
                style={{ display: "flex", width: "400px", gap: "160px" }}
              >
                <span style={{ fontSize: "13.32px",color:"#8A8A8A" }} >Ödənilən məbləğ</span>
                <span className="amount font-bold text-[#000000]">{amount}₼</span>
              </div>
              <div className="button-group">
                <button type="submit" className="submit-button">
                  Ödəniş et
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={handleClose}
                >
                  İmtina et
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const styles = {
  gridItem: {
    border: "1px solid #e5e5e5",
    padding: "16px",
    width: "406px",
    height: "270px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    textAlign: "left",
  },
  fundingHeader: {
    fontWeight: "400",
    marginBottom: "8px",
    marginTop: "5px",
    fontSize: "10px",
  },
  fundingBarContainer: {
    width: "365px",
    height: "24px",
    backgroundColor: "inherit",
    borderRadius: "12px",
    position: "relative",
    marginBottom: "16px",
    overflow: "hidden",
    border: "1px solid #7488ED",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fundingBar: {
    width: "100%",
    height: "20px",
    position: "relative",
    marginLeft: "1px",
  },
  fundingFilled: {
    height: "20px",
    backgroundColor: "#7F9CF5",
    borderRadius: "12px",
  },
  fundingAmount: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "14px",
    fontWeight: "600",
    color: "#1A1A1A",
    zIndex: 2,
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    marginLeft: "5px",
  },
  image: {
    width: "19px",
    height: "19px",
    marginRight: "8px",
  },
  imageText: {
    fontSize: "14px",
    color: "#1a1a1a",
  },
  donateButton: {
    padding: "8px 16px",
    backgroundColor: "#05AC58",
    color: "#FFFFFF",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "700",
    marginTop: "10px",
    width: "365px",
  },
};
export default MosquePayment;
