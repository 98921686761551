import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import dropdown from "../assets/images/dropdown.svg";
import MosqueCard from "./MosqueCard";

const Language = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("Az");
  const [selectedCurrency, setSelectedCurrency] = useState("AZN");
  const [mosques, setMosques] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const languageRef = useRef(null);
  const currencyRef = useRef(null);
  const popupRef = useRef(null);

  useEffect(() => {
    fetchActiveCharities();
  }, []);

  useEffect(() => {
    setShowPopup(false);
    setLanguageDropdownOpen(false);
    setCurrencyDropdownOpen(false);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      setLanguageDropdownOpen(false);
      setCurrencyDropdownOpen(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        languageRef.current && 
        !languageRef.current.contains(event.target) &&
        currencyRef.current && 
        !currencyRef.current.contains(event.target)
      ) {
        setLanguageDropdownOpen(false);
        setCurrencyDropdownOpen(false);
      }

      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchActiveCharities = async () => {
    const accessToken = Cookies.get('accessToken');
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.jumma.app/ActiveCharities', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const charities = data.activeCharityListItemDtos.map((charity, index) => ({
        id: index + 1,
        name: charity.mosqueName,
        address: charity.mosqueAddress,
        repairType: charity.problem,
        cost: charity.requiredAmount,
        fundedAmount: charity.collectedAmount,
        totalAmount: charity.requiredAmount,
      }));
      setMosques(charities);
      console.log(charities)
    } catch (error) {
      console.error('Error fetching active charities:', error);
      setError(`Failed to fetch active charities: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDonate = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen((prev) => !prev);
    setCurrencyDropdownOpen(false);
  };

  const toggleCurrencyDropdown = () => {
    setCurrencyDropdownOpen((prev) => !prev);
    setLanguageDropdownOpen(false);
  };

  const handleLanguageSelect = (lang) => {
    setSelectedLanguage(lang);
    setLanguageDropdownOpen(false);
  };

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
    setCurrencyDropdownOpen(false);
  };

  const handleMosqueClick = (mosque) => {
    navigate('/mosque-payment', { 
      state: { mosque }
    });
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <div className="hidden sm:flex flex-col md:flex-row items-center justify-between md:space-x-8 w-full max-w-screen-xl py-2">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Link to="/becomeaseller">
            <button className="text-[#818181] font-bold py-[6px] ml-[20px]">
              Satıcı ol
            </button>
          </Link>
        </div>

        <div className="flex items-center space-x-4 mt-2 md:mt-0">
          
          <button
            style={{ backgroundColor: "#05AC58" }}
            className="text-white px-12 py-1.5 text-sm font-medium mr-5"
            onClick={handleDonate}
          >
            İANƏ ET
          </button>
          <div className="relative" ref={languageRef}>
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={toggleLanguageDropdown}
            >
              <span className="text-sm text-[#1a1a1a]">{selectedLanguage}</span>
              <img className="w-5 h-5" src={dropdown} alt="dropdown" />
            </div>
            {languageDropdownOpen && (
              <div
                className="absolute mt-2 w-24 bg-white border border-gray-300 shadow-lg rounded"
                style={{
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 1000,
                }}
              >
                <div className="flex flex-col">
                  <span
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleLanguageSelect("Az")}
                  >
                    Az
                  </span>
                  <span
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleLanguageSelect("En")}
                  >
                    En
                  </span>
                  <span
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleLanguageSelect("Ru")}
                  >
                    Ru
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="relative" ref={currencyRef}>
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={toggleCurrencyDropdown}
            >
              <span className="text-sm text-[#1a1a1a]">{selectedCurrency}</span>
              <img className="w-5 h-5" src={dropdown} alt="dropdown" />
            </div>
            {currencyDropdownOpen && (
              <div
                className="absolute mt-2 w-24 bg-white border border-gray-300 shadow-lg rounded"
                style={{
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 1000,
                }}
              >
                <div className="flex flex-col">
                  <span
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleCurrencySelect("AZN")}
                  >
                    AZN
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div ref={popupRef} className="bg-white rounded-lg overflow-hidden w-[440px] sm:w-full sm:max-w-4xl max-h-[90vh] flex flex-col">
            <div className="p-4 border-b border-b-[#D7D7D7]">
              <div className="flex justify-between items-center">
                <h2 className="text-lg sm:text-xl font-bold">Məscidlərimizə Dəstək Olun</h2>
                <button
                  onClick={closePopup}
                  className="text-2xl font-bold"
                >
                  &times;
                </button>
              </div>
              <p className="text-sm text-gray-600 mt-2">
                Sizin ianəniz icma məscidlərimizin saxlanmasına və
                təkmilləşdirilməsinə kömək edir. Hər töhfə sayılır. Səxavətinizə
                görə təşəkkür edirem.
              </p>
            </div>
            <div className="overflow-y-auto flex-grow p-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {mosques.map((mosque) => (
                  <MosqueCard 
                    key={mosque.id} 
                    {...mosque} 
                    onClick={() => handleMosqueClick(mosque)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Language;