import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import IaneCard from "../../components/donation add card/ianeCard";

const IanePage = () => {
  const [findMescid, setFindMescid] = useState(null);
  const { params } = useParams();

  useEffect(() => {
    const fetchActiveCharities = async () => {
      const accessToken = Cookies.get("accessToken");
  
      try {
        const response = await fetch("https://api.jumma.app/ActiveCharities", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        const charities = data.activeCharityListItemDtos.map(
          (charity, index) => ({
            id: index + 1,
            name: charity.mosqueName,
            address: charity.mosqueAddress,
            repairType: charity.problem,
            cost: charity.requiredAmount,
            fundedAmount: charity.collectedAmount,
            totalAmount: charity.requiredAmount,
          })
        );
    
        
        const matchingMescid = charities.find((item) => item.id === Number(params));
        setFindMescid(matchingMescid);
      } catch (error) {
        console.error("Error fetching active charities:", error);
      }
    };
    fetchActiveCharities()
  }, [params]);

  return (
    <div className="w-full max-w-screen-xl mt-4 space-y-8">
      {findMescid ? (
        <IaneCard findMescid={findMescid} />
      ) : (
        <p>Loading mosque information...</p>
      )}
   
    </div>
  );
};

export default IanePage;