import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import AccountPage from "../pages/Account";
import SignInPage from "../pages/SigIn";
import LoginPage from "../pages/Login";
import CardPage from "../pages/Card";
import ContactUsPage from "../pages/ContactUs";
import BecomeSeller from "../components/BecomeASeller.jsx";
import ProductPage from "../pages/ProductCard";
import AddAddress from "../pages/Add Address";
import AddCard from "../pages/AddCard/index";
import Location from "../components/Location/Location.jsx";
import CategoryPage from "../pages/CategoryPage";
import AppLayout from "../layouts/AppLayout";
import Edit from "../pages/EditProduct/index";
import { AppContextProvider } from "../context/AppContext";
import DashboardContent from "../components/DashboardContent(Admin)/DashboardContent";
import ProductContent from "../components/ProductContent(Admin)/ProductContent";
import OrdersContent from "../components/OrdersContent(Admin)/OrdersContent";
import CustomersContent from "../components/CustomerContent(Admin)/CustomersContent";
import AddProductContent from "../components/AddProductContent(Admin)/AddProductContent";
import SettingsContent from "../components/SettingsContent(Admin)/SettingsContent";
import AdminLayout from "../layouts/AdminLayout";
import EditProduct from "../components/ProductContent(Admin)/EditProduct.js";
import AdminView from "../components/AdminView/AdminView.jsx";
import SaticiAdminHesab from "../components/SaticiAdminHesab.jsx";
import AdminReviewDetail from "../components/AdminReviewDetail.jsx";

import ForgetPassword from "../components/ForgetPassword.jsx";
import ResetPassword from "../components/ResetPassword.jsx";
import { AdminContextProvider } from "../context/AdminContext.jsx";
import MainAdminContextProvider from "../context/MainAdminContext.jsx";
import MainAdminLayout from "../layouts/MainAdmin.jsx";
import MainAdminAna from "../components/MainAdminAna/MainAdminAna.jsx";
import MainAdminHesab from "../components/MainAdminHesab/MainAdminHesab.jsx";
import MainAdminSatici from "../components/MainAdminSatici/MainAdminSatici.jsx";
import MainAdminMescid from "../components/MainAdminMescid/MainAdminMescid.jsx";
import MainAdminIstifadeciler from "../components/MainAdminIstifadeciler/MainAdminIstifadeciler.jsx";
import MainKateqoriyalar from "../components/MainAdminKateqoriyalar/MainAdminKateqoriyalar.jsx"
import MehsullarPage from "../pages/Mehsullar/index.jsx";
import IanePage from "../pages/IanePage/index.jsx";
import SellerDetails from "../components/MainAdminSatici/mainadminsaticidetails.jsx";
import ProductDetails from "../components/ProductContent(Admin)/ProductDetails.js";
import SellerapprovedDetails from "../components/MainAdminSatici/Deatilsapproved.jsx";
import Tezlikle from "../components/Tezlikle.jsx";
import MainAdminIane from "../components/MainAdminIane/mainIane.jsx";


const router = [
  {
    path: "/tezlikle",
    element: <Home></Home>,
    layout: "app",
  },
  {
    path: "/",
    element: <Tezlikle></Tezlikle>,
  
  },
  {
    path: "/resetpassword",
    element: <ResetPassword />,
    layout: "app",
  },
  {
    path: "/forgetpassword",
    element: <ForgetPassword />,
    layout: "app",
  },
  {
    path: "/account/*",
    element: <AccountPage></AccountPage>,
    layout: "app",
  },
  {
    path: "register",
    element: <SignInPage></SignInPage>,
    layout: "app",
  },
  {
    path: "contactus",
    element: <ContactUsPage></ContactUsPage>,
    layout: "app",
  },
  {
    path: "becomeaseller",
    element: <BecomeSeller></BecomeSeller>,
    layout: "app",
  },
  {
    path: "/edit",
    element: <Edit></Edit>,
    layout: "app",
  },
  {
    path: "mehsullar/:slug",
    element:<MehsullarPage></MehsullarPage>,
    layout: "app",
  },
  
  {
    path: "login",
    element: <LoginPage></LoginPage>,
    layout: "app",
  },

  {
    path: "card",
    element: <CardPage></CardPage>,
    layout: "app",
  },
  {
    path: "product-card/:slug",
    element: <ProductPage></ProductPage>,
    layout: "app",
  },
  {
    path: "add-address",
    element: <AddAddress></AddAddress>,
    layout: "app",
  },
  {
    path: "add-card",
    element: <AddCard></AddCard>,
    layout: "app",
  },
  {
    path: "add-card-donation/:params",
    element: <IanePage></IanePage>,
  
  },
  {
    path: "location",
    element: <Location></Location>,
    layout: "app",
  },
  {
    path: "/categories/:category",
    element: <CategoryPage></CategoryPage>,
    layout: "app",
  }, 
  // saticiadminpanel
  {
    path: "/admin-panel/*",
    element: <AdminLayout></AdminLayout>,
  },

  {
    path: "admin-panel/location",
    element: <Location></Location>,
    layout: "app",
  },
  {
    path: "admin-panel/dashboard",
    element: <DashboardContent></DashboardContent>,
    layout: "admin",
  },
  {
    path: "admin-panel/product",
    element: <ProductContent></ProductContent>,
    layout: "admin",
  },
  {
    path: "admin-panel/orders",
    element: <OrdersContent></OrdersContent>,
    layout: "admin",
  },
  {
    path: "admin-panel/customers",
    element: <CustomersContent></CustomersContent>,
    layout: "admin",
  },
  {
    path: "admin-panel/add-product",
    element: <AddProductContent></AddProductContent>,
    layout: "admin",
  },
  {
    path: "admin-panel/reviews",
    element: <SettingsContent></SettingsContent>,
    layout: "admin",
  },
  {
    path: "editproduct/:slug",
    element: <EditProduct></EditProduct>,
    layout: "admin",
  },
  {
    path: "adminview/:orderId",
    element: <AdminView />,
    layout: "admin",
  },
  {
    path: "admin-panel/hesab",
    element: <SaticiAdminHesab></SaticiAdminHesab>,
    layout: "admin",
  },
  {
    path: "reviewdetail/:slug",
    element: <AdminReviewDetail></AdminReviewDetail>,
    layout: "admin",
  },
  {
    path: "admin-panel/reviewdetail/:slug",
    element: <AdminReviewDetail></AdminReviewDetail>,
    layout: "admin",
  },
  {
    path: "product-details/:productId",
    element: <ProductDetails />,
    layout: "admin",
  },
  
  // esas admin panel
  {
    path: "mainAdmin-panel/*",
    element: <MainAdminLayout></MainAdminLayout>,
  },
  {
    path: "mainAdmin-panel/anasehife",
    element:<MainAdminAna></MainAdminAna>,
    layout: "mainAdmin",
  },
  {
    path: "mainAdmin-panel/hesab",
    element:<MainAdminHesab></MainAdminHesab>,
    layout: "mainAdmin",
  },
  {
    path: "mainAdmin-panel/satici",
    element:<MainAdminSatici></MainAdminSatici>,
    layout: "mainAdmin",
  },
  {
    path: "mainAdmin-panel/mescid",
    element:<MainAdminMescid></MainAdminMescid>,
    layout: "mainAdmin",
  },
  {
    path: "mainAdmin-panel/istifadeciler",
    element:<MainAdminIstifadeciler></MainAdminIstifadeciler>,
    layout: "mainAdmin",
  },
  {
    path: "mainAdmin-panel/kateqoriyalar",
    element:<MainKateqoriyalar></MainKateqoriyalar>,
    layout: "mainAdmin",
  },
  {
    path: "mainAdmin-panel/satici",
    element: <MainAdminSatici></MainAdminSatici>,
    layout: "mainAdmin",
  },
  {
    path: "mainAdmin-panel/satici/:sellerId",
    element: <SellerDetails />,
    layout: "mainAdmin",
  },

  {
    path: "mainAdmin-panel/detailsapproved/:sellerId", 
    element: <SellerapprovedDetails />,
    layout: "mainAdmin",
  },
  {
    path: "mainAdmin-panel/Iane", 
    element:<MainAdminIane></MainAdminIane>,
    layout: "mainAdmin",
  },
];

const routerMap = (arr) =>
  arr.map((router) => {
    if (router.layout) {
      if (router.layout === "app") {
        router.element = (
          <AppContextProvider>
            <AppLayout>{router.element}</AppLayout>
          </AppContextProvider>
        );
      } else if (router.layout === "admin") {
        router.element = (
          <AdminContextProvider>
            <AdminLayout>{router.element}</AdminLayout>
          </AdminContextProvider>
        );
      } else if (router.layout === "mainAdmin") {
        router.element = (
          <MainAdminContextProvider>
            <MainAdminLayout>{router.element}</MainAdminLayout>
          </MainAdminContextProvider>
        );
      }
    }
    return router;
  });

export default createBrowserRouter(routerMap(router));
