
import { Link } from 'react-router-dom';
// import defaultMosqueImage from "../assets/images/mescid.png";
import img1 from "../assets/images/mescid.png"
import image2 from "../assets/images/address.png";
import image3 from "../assets/images/repair.png";
import image4 from "../assets/images/cost.png";

const MosqueCard = ({ id, name, address, repairType, cost, fundedAmount, totalAmount }) => {
 
  const fundingPercentage = (fundedAmount / totalAmount) * 100;


  return (
    <div style={styles.gridItem}>
      <div style={styles.fundingHeader}>Maliyyələşdirilib</div>
      <div style={styles.fundingBarContainer}>
        <div style={styles.fundingBar}>
          <div style={{...styles.fundingFilled, width: `${fundingPercentage}%`}}></div>
        </div>
        <div style={styles.fundingAmount}>{fundedAmount}₼</div>
      </div>
      <div style={styles.imageContainer}>
        <img src={img1} alt="location" style={styles.image} />
        <span style={styles.imageText}>{name}</span>
      </div>
      <div style={styles.imageContainer}>
        <img src={image2} alt="address" style={styles.image} />
        <span style={styles.imageText}>{address}</span>
      </div>
      <div style={styles.imageContainer}>
        <img src={image3} alt="repair" style={styles.image} />
        <span style={styles.imageText}>{repairType}</span>
      </div>
      <div style={styles.imageContainer}>
        <img src={image4} alt="cost" style={styles.image} />
        <span style={styles.imageText}>{cost} manat</span>
      </div>
      <Link to="/location" state={{ mosqueId: id, amount: '20₼' }}>
        <button style={styles.donateButton}>İANƏ ET</button>
      </Link>
    </div>
  );
};

const styles = {
  gridItem: {
    border: "1px solid #e5e5e5",
    padding: "16px",
    width: "406px",
    height: "270px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    textAlign: "left",
  },
  fundingHeader: {
    fontWeight: "400",
    marginBottom: "8px",
    marginTop: "5px",
    fontSize:'10px',
  },
  fundingBarContainer: {
    width: "365px",
    height: "24px",
    backgroundColor: "inherit",
    borderRadius: "12px",
    position: "relative",
    marginBottom: "16px",
    overflow: "hidden",
    border: "1px solid #7488ED",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fundingBar: {
    width: "100%",
    height: "20px",
    position: "relative",
    marginLeft:"1px",
  },
  fundingFilled: {
    height: "20px",
    backgroundColor: "#7F9CF5",
    borderRadius: "12px",
  },
  fundingAmount: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "14px",
    fontWeight: "600",
    color: "#1A1A1A",
    zIndex: 2,
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    marginLeft:"5px",
  },
  image: {
    width: "19px",
    height: "19px",
    marginRight: "8px",
  },
  imageText: {
    fontSize: "14px",
    color: "#1a1a1a",
  },
  donateButton: {
    padding: "8px 16px",
    backgroundColor: "#05AC58",
    color: "#FFFFFF",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "700",
    marginTop: "10px",
    width:"365px",
  },
};

export default MosqueCard;